@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import 'app/shared/styles/index';

html.unlockScroll, html.unlockScroll body, html.unlockScroll #layout {
  overflow: unset !important;
  height: unset !important;
  min-height: 100% !important;
}

input, select {
    transition-duration: 0s !important;
  &:focus {
    border: 3px solid var(--clr-primary-lt) !important;
  }
}

#layout {
  @include minSize(lg) {
    max-width: 1600px !important;
    padding: 0 calc(var(--pagePaddingX)/2) !important;

    #viewTitle {
      .titleText {
        width: calc(100% - var(--pagePaddingX)*12) !important;
        justify-content: center;
        height: 50px;
        border-radius: var(--border-btn-sm);
      }
      .btn-choose-lang {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 6px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .resp_wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 2%;

      &.resp_wrap {
        flex-wrap: wrap;
      }
      &.resp_row{
        flex-direction: row;
      }

      .resp_l {
        @include minSize(lg) {
          &-50 {
            width: 50%;
          }
        }
      }

      .resp_c {
        @include minSize(lg) {
          &-50 {
            width: 100%;
            max-width: 500px;
            margin-right: auto;
            margin-left: auto;
            flex-grow: unset;
          }
        }
      }

      .resp_r {
        @include minSize(lg) {
          &-50 {
            width: 50%;
          }
        }
      }
    }
  }
}

.scroll_wrapper {
  padding-bottom: 0 !important;
  @include maxSize(lg) {
  padding: 0 20px 20px 20px !important;
  margin-left: -20px !important;
  margin-right: -20px !important;
  }
}

.resp_scroll-wrapper {
  &_l {
    @include minSize(lg) {
      /*overflow-x: hidden;
      overflow-y: scroll;*/
      margin-bottom: var(--scrollWrapper-mb);
      margin-top: var(--scrollWrapper-mt);
      padding-bottom: 5vh;
      padding-top: 20px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      z-index: 0;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.resp_minSize {
  &_l {
    @include maxSize(lg) {
      display: none !important;
    }
  }
  &_xl {
    @include maxSize(xl) {
      display: none !important;
    }
  }
}

.resp_maxSize {
  &_l {
    @include minSize(lg) {
      display: none !important;
    }
  }
  &_xl {
    @include minSize(xl) {
      display: none !important;
    }
  }
}

.bold {
  font-weight: bold;
  button {
    font-weight: bold !important;
  }
}

.no-pointer {
  pointer-events: none;
}

#scanner {
  @include minSize(lg) {
    height: 100% !important;
    max-height: 800px;
    aspect-ratio: 1/1;
    bottom: 0;
    border-radius: 40px;
    overflow: hidden;
  }
}

.parent-container {
    display: flex;
    max-width: 1000px;
    width: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    & > * {
        flex: 1;
    }
  @include maxSize(lg) {
    max-width: 700px;
  }

  @include maxSize(lg) {
      flex-direction: column;
  }

  &_md {
    max-width: 700px;
  }
}

.menuButton-container {
  max-width: 400px;
  width: 100%;
  @include minSize(lg) {
    & > * {
      flex: 1;
    }
  }
}


.btns-container{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  @include maxSize(lg) {
    margin-top: 10px;
    align-items: flex-end;
    flex-grow: 1;
    margin-bottom: 10px;
  }
}

.slideElement, .datetime-range-picker:not(:focus,.hasValue), .input_wrapper input:not(:focus,.hasValue), .input_wrapper textarea:not(:focus,.hasValue), .input_wrapper select:not(:focus,.hasValue) {
  border: 1px solid rgba(0,0,0,0.3) !important;
}

.cursor-pointer {
  cursor: pointer;
}

button:disabled {
  background-color: grey!important;
}


// For mobile devices
#layout{
  height: 100svh !important;
}
.checkbox_element .icon_wrapper {
  min-height: unset !important;
}

.input_wrapper label {
  white-space: pre;
  width: 100%;
  align-items: flex-start !important;
}

app-view-title {
  @include maxSize(lg) {
    height: auto !important;
    min-height: unset !important;
    margin-top: 20px !important;

    #viewTitle {
      height: unset !important;
      min-height: unset !important;
      overflow: unset !important;
      position: static !important;

      .titleText {
        position: static !important;
        height: unset !important;
        min-height: unset !important;
        padding: 10px 20px !important;
        justify-content: center !important;
        bottom: unset !important;
        width: 100% !important;
        font-size: 1.1rem !important;
      }
    }
  }
}

body .view_deco_image img {
  max-height: 160px !important;
  margin: 30px 0 !important;

  @include maxSize(lg) {
    max-height: 120px !important;
    margin: 20px 0 !important;
  }
  @include height(sm) {
    max-height: 120px !important;
  }
}

.mat-mdc-dialog-content {
  max-height: 80vh !important;
}

.dialog_close .btn{
  background-color: #bc232c!important;
}

.checkbox_element {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.fullHeight {
  height: 100% !important;
  &-noMobile{
    height: 100%;
    @media (max-width: 992px) {
      height: unset !important;
    }
  }
}

#form_reset_btn {
  position: fixed;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
}

#glpi_widget {
  @include maxSize(lg) {
    top: 0 !important;
    right: 0 !important;
    bottom: unset !important;
  }
}

app-collapse .mat-expansion-indicator {
  filter: brightness(0);
}

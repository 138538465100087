body {
  --clr-primary: #2c3c43 !important;
  --clr-primary-dk: #1A2327 !important;
  --clr-primary-lt: #405962 !important;
  --clr-primary-ltr: #dee4e6 !important;

  --clr-secondary: rgba(0,0,0,0.5); //rgb(55, 65, 81)

  --clr-dk: rgba(0,0,0,0.8);
  --clr-lt: #ffffff;
  --clr-gray: #EDEDED;
  --clr-gray-lt: #ffffff;
  --clr-gray-dk: #7D7D7D;

  --clr-btn-primary: rgba(0, 0, 0, 0.7);
  --clr-btn-primary-h: rgba(0, 0, 0, 0.8);
  --clr-btn-secondary: rgba(203, 203, 203, 0.5);
  --clr-btn-secondary-h: rgba(180, 180, 180, 0.5);

  --pagePaddingX: 4%;
  --appBackground: linear-gradient(170deg, #e9eff1, #e2eaec)!important;

  --border-base: 3px;
  --border-md: 6px;
  --border-l: 24px !important;
  --border-xl: 30px;
  --border-btn-sm: 10px;
  --border-btn-md: 100px !important;

  --slideElementBg: var(--clr-lt) !important;
  --slideElementClr: var(--clr-dk) !important;
  --slideElementIconRClr: var(--clr-dk) !important;
  --slideElementIconLClr: var(--clr-primary-lt) !important;
  --slideElementIconBg: transparent !important;
  --slideElementIconFs: 24px !important;

  --time-base: 0.2s;
  --time-md: 0.3s;
  --time-l: 0.5s;
  --time-xl: 1s;

  --clr-bg-grad1: rgba(0,0,0,0.4);
  --clr-bg-grad2: rgba(0,0,0,0.4);
  --clr-line-grad1: #3D54FD;
  --clr-line-grad2: #FD3D54;

  --box-sh-dk-xs: 1px 1px 0px rgba(0,0,0,0.2);
  --box-sh-dk-sm: 2px 2px 1px rgba(0,0,0,0.2);
  --box-sh-dk-md: 4px 4px 1px rgba(0,0,0,0.2);
  --box-sh-dk-l: 6px 6px 2px rgba(0,0,0,0.2);

  --scrollWrapper-mt: 0 !important;
  --scrollWrapper-mb: 0 !important;


  /* base font size - applied at body / html level */
  --fs-base: 16px;
  --fs-giga: 80px;
  --fs-mega: 70px;

  /* heading font sizes */
  --fs-h3: 28px;
  --fs-h4: 24px;
  --fs-h5: 20px;
  --fs-h6: 18px;

  /* larger than heading font sizes */
  --fs-kilo: 60px;
  --fs-h1: 36px;
  --fs-h2: 32px;

  /* smaller than heading font sizes */
  --fs-milli: 14px;
  --fs-micro: 12px;
  --fs-nano:  10px;
  --fs-pico: 8px;
}

body.kiosk {
  font-size: 1.4rem !important;
  line-height: 1.3 !important;

  p, .errors_list_item, .btn, .mat-expansion-panel-header-title, .desc-card__descL {
    font-size: 1.4rem !important;
    line-height: 1.3 !important;
  }

  .summary_line {
    font-size: 1rem !important;
  }

  .desc-card fa-icon:not(.sideElement_icon), .checkbox_element label i, .checkbox_element label fa-icon {
    font-size: 30px !important;
  }

  .checkbox_element label {
    padding: 16px 66px 16px 20px !important;
  }

  #viewTitle .titleText {
    font-size: 1.8rem !important;
    line-height: 1.3 !important;
  }

  .nationality_section {
    padding: 30px !important;
    img {
      width: 140px !important;
    }
    form {
      margin-top: 30px !important;
    }

    .gap_10 {
      gap: 30px !important;
    }
  }

  .input_wrapper {
    select, input {
      height: 60px !important;
    }

    input.hasValue + label, .input_wrapper input.ng-valid:not(.ng-untouched).hasValue + label, label {
      font-size: 1.3rem !important;
    }
  }

  .gap_25 {
    gap: 32px !important;
  }

  .scroll_wrapper {
    padding-top: 2% !important;
  }

  .choice_button {
    gap: 20px !important;
    &-btns {
      button {
        font-size: 1.5rem !important;
        padding: 10px 20px !important;
      }
    }
  }

  .big_btn span {
    font-size: 1.5rem !important;
  }
}

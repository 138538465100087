.big_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--clr-lt);
  padding: 15px;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 300ms;
  box-shadow: 0 8px 12px -3px rgba(0,0,0,0.1);
  max-width: calc(50% - 5px);
  @include maxSize(lg) {
    padding: 15px 10px;
  }

  fa-icon {
    font-size: 4rem;
    color: var(--clr-primary);
  }

  img {
    width: 40%;
    @include maxSize(lg) {
      width: 50%;
      min-width: 100px;
    }
  }

  span {
    margin-top: 18px;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
  }

  p {
    margin-top: 10px !important;
    text-align: center;
    font-size: 1rem;
    opacity: 0.9;
  }

  &:hover, &:active {
    transform: scale(1.05);
  }

  &-resp {
    max-width: calc(33% - 5px);

    @include maxSize(lg) {
      max-width: calc(50% - 5px);
    }
  }

  &-selected {
    box-shadow: inset 0 0 0 6px var(--clr-primary), 0 8px 12px -3px rgba(0,0,0,0.1);
  }
}

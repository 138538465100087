@import 'variables';

.standby_layout {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2%;

  .standby_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-width: 80%;
    h1 {
      font-size: 4.5rem;
      font-weight: 600;
      line-height: 1;
      text-align: center;
      margin: 0;
      font-family: 'RobotoCondensed', sans-serif;
    }
    h2 {
      font-size: 2.8rem;
      font-weight: 500;
      line-height: 1;
      max-width: 70%;
      text-align: center;
      margin-top: 6%;
      font-family: 'RobotoCondensed', sans-serif;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.3;
      @include maxSize(xl) {
        font-size: 1.1rem;
      }
    }
    .standby_desc {
      margin: 5% 0;
      @include maxSize(xl) {
        margin: 12% 0;
      }
      ol {
        margin: 20px 0;
        font-size: 1.5rem;
        @include maxSize(xl) {
          font-size: 1.3rem;
          margin: 30px 0;
        }
        li {
          margin: 8px 0;
          line-height: 1.2;
          background: rgba(255,255,255,1);
          padding: 10px 20px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          gap: 20px;

          img {
            width: 50px;
            height: 50px;
            opacity: 0.8;
          }
        }
      }
    }
  }
  .standby_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: 100vw;
    gap: 2%;

    button {
      font-size: 3rem;
      padding: 30px 90px;
      background: #95CD00;
      text-transform: uppercase;
      color: #fff;
      border-radius: 20px;
      font-weight: bold;
      box-shadow: inset 7px 6px 6px rgba(255,255,255,0.4), inset -5px -5px 6px rgba(0,0,0,0.25), 2px 6px 8px rgba(0,0,0,0.1);
      text-shadow: 1px 2px 2px rgba(0,0,0,0.3);
      transition: all 0.2s;

      &:hover, &:active {
        transform: scale(1.05);
        filter: brightness(1.03);
      }
    }

    .standby_bigImg {
      width: 100%;
      overflow: hidden;
      margin-bottom: -3%;
      border-radius: 200px 200px 0 0;
      box-shadow: 0 -3px 40px rgba(0,0,0,0.2);
      border: 1px solid #B38B64;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .standby_logo {
      width: 140px;
    }
  }
  #standby_hand {
    position: absolute;
    z-index: 1;
    animation: hand 7s infinite alternate-reverse ease-in-out;

    .ripples {
      position: absolute;
      z-index: -1;
      top: 12%;
      left: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      .ripple {
        position: absolute;
        display: block;
        border-radius: 100%;
        animation: ripple 2.5s infinite;

        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: 0.5s;
        }
        &:nth-child(3) {
          animation-delay: 1s;
        }
      }
    }
  }
}

@keyframes ripple {
  0% {
    width: 20px;
    height: 20px;
    opacity: 0;
    border: 20px solid rgba(#26329B, 0.15);
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
    border: 1px solid rgba(#26329B, 0.1);
  }
}

@keyframes hand {
  0% {
    top: 52%;
    left: 80%;
    transform: rotate(0deg);
  }
  40% {
    top: 52%;
    left: 80%;
    transform: rotate(0deg);
  }
  60% {
    top: 37%;
    left: 5%;
    transform: rotate(90deg);
  }
  100% {
    top: 37%;
    left: 5%;
    transform: rotate(90deg);
  }
}

@mixin maxSize($size) {
  @if $size == sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $size == lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $size == xl {
    @media (max-width: 1200px) {
      @content;
    }
  }
}

@mixin minSize($size) {
  @if $size == sm {
    @media (min-width: 576px) {
      @content;
    }
  }@else if $size == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin height($size) {
  @if $size == sm {
    @media (max-height: 700px) {
      @content;
    }
  }
}

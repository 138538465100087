@import './variables';

$borderSizeW: calc(100vw - 20px);
$borderSizeH: calc(100vh - 20px);

$circleSize: 90px;
$circlePos: -40px;

/*Reponsive*/
$smBorderSizeW: calc(100vw - 10px);
$smBorderSizeH: calc(100vh - 10px);

$smCircleSize: 70px;
$smCirclePos: -40px;


$outerColor: #ebedee;//#CEFBCE
$innerColor: #fdfbfb;//#f6fdf6

.background {
    width: 100vw;
    height: 100vh;
    background: lighten($innerColor, 10%);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: -1;

    span {
        width: $circleSize;
        height: $circleSize;
        background: lighten($innerColor, 10%);
        display: block;
        border-radius: 100%;
        position: absolute;
        z-index: 2;

        &:nth-of-type(1) {
            top: $circlePos;
            left: $circlePos;
        }
        &:nth-of-type(2) {
            top: $circlePos;
            right: $circlePos;
        }
        &:nth-of-type(3) {
            bottom: $circlePos;
            left: $circlePos;
        }
        &:nth-of-type(4) {
            bottom: $circlePos;
            right: $circlePos;
        }
    }

    &-inner {
        width: $borderSizeW;
        height: $borderSizeH;
        background: radial-gradient(darken($innerColor, 4%), darken($outerColor, 10%));
        position: absolute;
        z-index: 1;

        &-flag {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: absolute;
            animation: fadeIn 3s ease forwards;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                background: radial-gradient(rgba(200,200,200,0.6), rgba(0,0,0,0.6));
                backdrop-filter: blur(100px);
            }
        }
    }
}

@include maxSize(sm) {
  .background {
    background: darken($outerColor, 10%);

    span {
      background: darken($outerColor, 10%);
      width: $smCircleSize;
      height: $smCircleSize;

      &:nth-of-type(1) {
        top: $smCirclePos;
        left: $smCirclePos;
      }
      &:nth-of-type(2) {
        top: $smCirclePos;
        right: $smCirclePos;
      }
      &:nth-of-type(3) {
        bottom: $smCirclePos;
        left: $smCirclePos;
      }
      &:nth-of-type(4) {
        bottom: $smCirclePos;
        right: $smCirclePos;
      }
    }

    &-inner {
      width: $smBorderSizeW;
      height: $smBorderSizeH;
      background: var(--clr-lt);
    }

    &.dashboard {
      background: lighten($innerColor, 10%);

      span {
        background: lighten($innerColor, 10%);
      }

      .background-inner {
        background: radial-gradient(darken($innerColor, 4%), darken($outerColor, 10%));
      }
    }
  }
}

.table-wrapper{
  overflow: auto;

  table {
    font-size: var(--fs-milli);
    width: 100%;
    background: transparent !important;

    tr:not(.mat-header-row) {
      cursor: pointer;
      background: transparent !important;
      transition-duration: var(--time-base);

      @include maxSize(sm) {
        background: var(--clr-lt) !important;
      }

      &:hover{
        td, td:nth-child(even) {
          background: var(--clr-gray-lt) !important;
        }
      }

      td {
        padding: 0 10px;

        &:nth-child(even) {
          background: var(--clr-gray-ltr) !important;
        }
      }
    }
  }
}

@include maxSize(sm) {
  .mat-paginator {
    background: transparent !important;
    .mat-paginator-container {
      justify-content: flex-start !important;
    }
  }
}

.user_bubbles {
  .user_bubble {
    width: 20px;
    height: 20px;
    background: var(--clr-gray);
    border-radius: 100%;
    font-size: var(--fs-milli);

    &:not(:last-of-type) {
      margin-right: 2px;
    }
  }
}
.logo_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  @include maxSize(sm) {
    margin-bottom: 0;
    margin-top: 0;
    flex-direction: column;
  }


  .logo_img {
    border-radius: 100%;
    width: 100px;
    box-shadow: 2px 3px 0 rgba(0, 0, 0, 0.1);

    @include maxSize(sm) {
      width: 80px;
    }
  }

  .logo_text {
    display: block;
    text-align: center;
    font-size: var(--fs-kilo);
    font-family: Varela, sans-serif;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);
    margin-left: 18px;

    @include maxSize(sm) {
      font-size: var(--fs-h2);
      margin-top: 16px;
      margin-left: 0;
    }
  }

}
